import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  DontDo,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import alertPreviewData from '../../../../data/previews/alert.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const Button = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Button;
    return <Component {...props} />;
  },
});

const Alert = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Alert;
    return <Component {...props} />;
  },
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUse: 'acknowledgement',
      currentButton: 'single',
      isUsesOpen: false,
      isButtonsOpen: false,
    };
  }

  handleUseChange(value) {
    this.setState({
      currentUse: value,
    });
  }

  handleButtonChange(value) {
    this.setState({
      currentButton: value,
    });
  }

  handleUseAlertClick = () => {
    this.setState({ isUsesOpen: true });
  };

  handleButtonAlertClick = () => {
    this.setState({ isButtonsOpen: true });
  };

  handleUsesClose = () => {
    this.setState({ isUsesOpen: false });
  };

  handleButtonsClose = () => {
    this.setState({ isButtonsOpen: false });
  };

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Alert"
        designCode
        subnav="components">
        <PageHero heroData={pageHeroData} tierTwo="Dialogs" tierThree="Alert" />
        <V5Notice
          componentName="Alert"
          storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/overlays-dialogs--docs#alert"
        />

        <PageNavigation
          links={[
            'Cases',
            'Buttons',
            'Mobile',
            'Usage',
            'Microcopy',
            'Accessibility',
            'Platform',
          ]}
        />

        <Section title="Cases">
          <Paragraph>
            The two practical applications of an alert are an acknowledgement
            and a decision.
          </Paragraph>
          <ComponentPreview
            name="AlertUses"
            layout="row"
            previewData={alertPreviewData.uses}
            onChange={this.handleUseChange.bind(this)}>
            <Button
              buttonType="primary"
              onClick={this.handleUseAlertClick}
              ignoreThisComponent>
              Trigger Alert
            </Button>
            <Alert
              isOpen={this.state.isUsesOpen}
              actions={
                this.state.currentUse === 'acknowledgement'
                  ? [
                    {
                      buttonType: 'primary',
                      text: 'Okay',
                      onClick: this.handleUsesClose,
                    },
                  ]
                  : [
                    {
                      buttonType: 'subtle',
                      text: 'Cancel',
                      onClick: this.handleUsesClose,
                    },
                    {
                      buttonType: 'destroy',
                      text: 'Delete',
                      onClick: this.handleUsesClose,
                    },
                  ]
              }
            />
          </ComponentPreview>
        </Section>

        <Section title="Buttons">
          <Paragraph>
            The number of buttons is determined by the alert’s purpose. An
            acknowledgement only requires one, while the decision should offer
            two.
          </Paragraph>
          <ComponentPreview
            name="AlertButtons"
            layout="row"
            previewData={alertPreviewData.buttons}
            onChange={this.handleButtonChange.bind(this)}>
            <Button
              buttonType="primary"
              onClick={this.handleButtonAlertClick}
              ignoreThisComponent>
              Trigger Alert
            </Button>
            <Alert
              isOpen={this.state.isButtonsOpen}
              actions={
                this.state.currentButton === 'single'
                  ? [
                    {
                      buttonType: 'primary',
                      text: 'I Agree',
                      onClick: this.handleButtonsClose,
                    },
                  ]
                  : [
                    {
                      buttonType: 'secondary',
                      text: 'Back to Sync',
                      onClick: this.handleButtonsClose,
                    },
                    {
                      buttonType: 'secondary',
                      text: 'Next',
                      onClick: this.handleButtonsClose,
                    },
                  ]
              }
            />
          </ComponentPreview>
          <SectionSubhead>Button Types</SectionSubhead>
          <Paragraph>
            <List>
              <li>
                For a <strong>single acknowledgement</strong>, use Primary.
              </li>
              <li>
                For a double instance where they’ve chosen to{' '}
                <strong>delete something</strong>, put Destroy on the right with
                Subtle on the left—the latter should allow them to opt out.
              </li>
              <li>
                For a double instance with <strong>no preferred action</strong>,
                use Secondary for both.
              </li>
              <li>
                For double with a <strong>clear preference</strong>, use Primary
                on the right and Subtle on the left.{' '}
              </li>
            </List>
          </Paragraph>
          <Paragraph>
            <Link href="/components/buttons/button" isDesignCodeLink>
              Our button guidelines
            </Link>{' '}
            can give you a closer look at each type and how to use them.
          </Paragraph>
        </Section>

        <Section title="Mobile">
          <SectionSubhead>Alert vs System Alert</SectionSubhead>
          <Paragraph>
            Reserve using the Uniform alert for actions that take place{' '}
            <em>in</em> the Hudl ecosystem–like adding a teammate to the roster
            or editing the final score of an event. Decisions that affect the
            physical device should make use of the operating system’s alert. For
            example, use a system alert when communicating <em>the device</em>{' '}
            is out of storage. We need to be clear to our users they are
            deleting video from their physical device and not their Hudl
            account.
          </Paragraph>
          <DontDo
            dontText="use a system alert when the message applies to Hudl content."
            doText="use alerts for Hudl-related decisions."
            imgFilename="alert-system-alert"
          />

          <SectionSubhead>Action Order</SectionSubhead>
          <Paragraph>
            When users have decisions to make in an alert, always list the
            action that will take them <em>forward</em> first.
          </Paragraph>
          <DontDo
            dontText="switch up the order of actions."
            doText="list the action the user has chosen to take first."
            imgFilename="alert-action-order"
          />
        </Section>

        <Section title="Usage">
          <SectionSubhead>Not Expected</SectionSubhead>
          <Paragraph>
            An alert should never be a “next step” or part of a workflow’s
            logical progression. It should only appear when the action is
            seemingly complete.
          </Paragraph>
          <DontDo
            dontText="use it to provide a whole new task."
            doText="consider the alert a follow-up rather than any sort of step."
            imgFilename="alert-task"
          />

          <SectionSubhead>Good for Errors</SectionSubhead>
          <Paragraph>
            Alerts are ideal for an error they should know about before going
            anywhere else. Make it clear something didn’t go as planned and they
            can/should remedy.
          </Paragraph>
          <DontDo
            dontText="interrupt with an unrelated issue."
            doText="use the alert for errors they may not notice otherwise."
            imgFilename="alert-issue"
          />

          <SectionSubhead>No Dismiss</SectionSubhead>
          <Paragraph>
            Action or acknowledgement is required with all alerts—it’s why we
            removed the dismiss.
          </Paragraph>
          <Paragraph>
            One of the options can be to cancel or “never mind”. We just want to
            make sure that decision to opt out is deliberate.
          </Paragraph>

          <SectionSubhead>No Links</SectionSubhead>
          <Paragraph>
            We shouldn’t distract from the actual thing they’re trying to do. If
            there are resources worth providing, direct them via text, but don’t
            give them an additional “out” via hyperlink.
          </Paragraph>
          <DontDo
            dontText="link off and prevent them from making the very important decision."
            doText="mention resources only if they’d make a difference."
            imgFilename="alert-link"
          />
          <Paragraph>
            You could also risk the user interpreting a button as a way to that
            destination. (Not great when deleting.)
          </Paragraph>
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Body Only</SectionSubhead>
          <Paragraph>
            Unlike the modal, the alert has no title or subtitle. We jump
            straight to the body to ensure they read everything all at once.
          </Paragraph>
          <DontDo
            dontText="be cryptic and dance around the alert’s purpose."
            doText="use complete sentences and make sure the content flows into the button copy."
            imgFilename="alert-clarity"
          />
          <Paragraph>
            (And by “flow” we mean{' '}
            <Link href="https://www.hudl.com/design/writing/writing-for/components/cta-buttons-links">
              congruence
            </Link>
            .)
          </Paragraph>

          <SectionSubhead>Content Length</SectionSubhead>
          <Paragraph>
            The body of the alert should be no longer than 150 characters long.
            If you can make it shorter, even better. Just shoot for two lines
            max.
          </Paragraph>
          <Paragraph>
            Get straight to the point and provide their options—don’t forget we
            just interrupted their workflow.
          </Paragraph>
          <DontDo
            dontText="write a novel explaining the cause and what they should do."
            doText="tell them what’s up and provide a course of action."
            imgFilename="alert-length"
          />

          <SectionSubhead>Buttons</SectionSubhead>
          <Paragraph>
            The button should very clearly state its action. Avoid complete
            sentences, opt for a strong verb phrase instead. Title case always.
          </Paragraph>
          <Paragraph>
            You can read more about button microcopy in our{' '}
            <Link href="/components/buttons/button" isDesignCodeLink>
              button guidelines
            </Link>
            .
          </Paragraph>
        </Section>

        <AccessibilityAuditComponentResultsSection componentName="Dialogs / Alert" />

        <Section title="Platform">
          <PlatformTable />
        </Section>
      </PageWithSubNav>
    );
  }
}
